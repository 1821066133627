<template>
  <div class=" ">
    <titleBar
      title=" "
      :back="false"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="small"
      actionButtonRoundBg="transparent"
      iconPack="far"
      :isHome="false"
      :actions="[
        {
          icon: 'search',
          to: '/explore',
          hidden: false,
          hiddenTablet: false,
        },
        {
          icon: 'bell',
          to: '/notifications',
          hidden: false,
          hiddenTablet: false,
        },
        /*  {
          icon: 'comment',
          to: '/messages',
          hidden: false,
          hiddenTablet: false,
        },*/
      ]"
    />

    <div class="section">
      <botsTabs v-show="false" />
      <exploreTabs v-show="false" />
    </div>

    <div class="section" v-show="false">
      <div class="container" style="max-width: 1000px">
        <!--   
        <div class="columns">
          <div class="column">
            <h1 class="title is-1">Talk with fun and helpful AIs</h1>
       
            <form @submit.prevent="searchBots">
              <b-field>
                <b-input
                  v-model="search"
                  placeholder="Search hundreds of AIs"
                  icon="fas fa-search"
                  expanded
                  rounded
                  size="is-large"
                  @keyup.native.enter="searchBots"
                ></b-input>
              </b-field>
            </form>
          </div>

          <div class="column is-4 is-hidden-mobile">
            <img
              src="https://cdn1.onlybots.cc/path/to/upload/folderehloTDTbng___face1.png"
              style="max-height: 200px; margin: auto; display: block"
            />
          </div>
        </div>


        ---->

        <!-- for each cats show buttons, felx dipslya 
        <div class="cat-buttons" style="margin-bottom: 45px">
          <router-link
            :to="`/explore-bots/${cat.value}`"
            class="button is-primary is-light"
            @click="searchBots(cat.value)"
            v-for="cat in cats"
            :key="cat.id"
          >
       
            <span class="icon is-small">
              <i :class="'icon far fa-' + cat.icon"></i>
            </span>
            <span> {{ cat.label }}</span>
          </router-link>
        </div>

        -->
      </div>
    </div>

    <FeaturedBots :blocks="blocks" />
    <div class="section">
      <div class="container home" v-if="!loadingProj">
        <!-- 
        <div class="columns is-multiline">
          <div class="column is-3-desktop is-12-tablet">
            <h1 class="title is-3">Your APIs</h1>
            <h1 class="subtitle is-5">Plan, prototype and manage API projects.</h1>
          

            <router-link :to="{ path: $store.main.state.userToken ? '/new' : '/login' }" active-class="is-active" exact>
              <b-button rounded>Add a new API</b-button>
            </router-link>
          </div>
          <div class="column">
            <div class="columns is-multiline">
              <div class="column is-6-tablet is-6-desktop is-4" v-for="i in projs" :key="i.id">
              
                <router-link :to="{ path: '/' + i.id }" active-class="is-active" exact>
                  <projCard :proj="i" />
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <router-link :to="{ path: '/explore' }">
          <b-button type="is-info is-outlined">Explore Public examples</b-button>
        </router-link>-->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import FeaturedBots from "@/components/FeaturedBots.vue";
import botsTabs from "@/components/nav/botsTabs.vue";
import exploreTabs from "@/components/nav/exploreTabs.vue";

import cats from "@/data/categories.js";

export default {
  metaInfo() {
    return {
      title: this.title,
    };
  },
  components: {
    FeaturedBots,
    botsTabs,
    exploreTabs,
    //  HelloWorld,
    //   projCard,
  },
  data() {
    return {
      blocks: [],

      projs: [],
      loadingProj: true,
      publi: [],
      // loadingOps: true,
    };
  },
  methods: {
    async performSearch() {
      this.isLoading = true;
      var q = this.search;
      //  alert(3);
      // perform your search here, then update results and isLoading accordingly
      // the following is a placeholder and should be replaced with your own search code

      //redirect to search page
      //withj ?q=serch

      this.$router.push({ path: "/search", query: { q: q } });
    },
    searchBots() {
      // console.log("searching for", this.search);
      this.$router.push(`/explore-bots/${this.$route.params.cat || "all"}/${this.search}`);
      // this.$router.push({ name: "search", params: { query: this.search } });
    },
    load() {
      var slug = this.$route.params.audienceSlug || "photographers";
      //   this.blocks = [];
      window.API.getFeaturedBots({ page: slug, format: "explore" }).then((res) => {
        //this.bots = proj;
        this.blocks = res;

        this.loading = false;
        window.prerenderReady = true;
        // console.log("PROJJ!", proj, proj.id);
      });
      /*
    window.API.getFeaturedBots({ brochureHome: 1, format: "audiences" }).then((proj2) => {
      //this.bots = proj;
      this.botCats = proj2;
      //  this.bots.push(promo);
      this.loadingBots = false;
      window.prerenderReady = true;
      // console.log("PROJJ!", proj, proj.id);
    });
    */
    },
  },
  watch: {
    "$route.params.audienceSlug"(to, from) {
      this.load();
    },
  },
  computed: {
    cats() {
      return cats;
    },
    title() {
      var bb = this.blocks;
      if (bb.length > 0) {
        var b = bb[0];
        return b.subtitle;
      }
      return "Explore Bots";
    },
  },
  mounted() {
    /*
    window.API.getPublicProjects().then((proj) => {
      this.publi = proj;
      this.loadingProjPubli = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/

    var u = this.$store.main.state.userId;

    //  this.projs = proj;
    this.loadingProj = false;
    this.load();

    /*
    window.API.getUserProjects(u).then((proj) => {
      this.projs = proj;
      this.loadingProj = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/
    /*
    window.API.getProjectOperations(this.$route.params.project).then((ops) => {
      var opId = this.$route.params.operation;
      this.ops = ops;
      this.loadingOps = false;
      this.op = ops.filter((i) => i.operationId == opId)[0]; //TODO: validaiton
    });*/
  },
};
</script>
<style scoped>
.card {
  margin-bottom: 20px;
}

.cat-buttons {
  display: grid;
  grid-template-columns: repeat(6, auto);
  gap: 12px;
}
.cat-buttons .button {
  padding: 30px 1px;
}

/* responsive mobile only */
@media only screen and (max-width: 768px) {
  .cat-buttons {
    grid-template-columns: repeat(3, auto);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .cat-buttons .button {
    justify-content: flex-start;
    padding-left: 30px;
  }
}
</style>
